import { Component, OnInit, Inject  } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: 'account-confirmation-box',
  template: `<h3 mat-dialog-title>Confirmation</h3>
              <mat-dialog-content class="mat-typography">
                   {{message}}
                </mat-dialog-content>
                <mat-dialog-actions align="end" style="margin-bottom:0px;">
                  <button type="button" (click)="close()" class="btn btn-block btn-danger col-sm-3" style="margin-right:10px;margin-top:9px;">No</button>
                  <button type="button" [mat-dialog-close]="true" class="btn btn-block btn-success col-sm-3">Yes</button>
             </mat-dialog-actions>`,
  styles: []
})
export class AccountConfirmationBoxComponent implements OnInit {

  dialog: MatDialogRef<AccountConfirmationBoxComponent>;
  constructor(private dialogRef: MatDialogRef<AccountConfirmationBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) {
    this.dialog = this.dialogRef;
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close(false);
  }

}
