import { Injectable, EventEmitter, Input, Output } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, observable } from 'rxjs';
import { AccountDataClass } from '../classes/AccountDataClass';
import { UserDataClass, AccountLocationModelClass } from '../classes/UserDataClass';
import { ActivationKeyDataClass } from '../classes/AccountDataClass';
import { map, take, first } from 'rxjs/operators';

import { catchError } from "rxjs/internal/operators";
import { debug } from 'util';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { AppBaseCommonService } from './app-base-common.service';

@Injectable({
  providedIn: 'root'
})

export class AppCommonService extends AppBaseCommonService {
  
  constructor(protected http: HttpClient,
    protected router: Router,
    protected cookieService: CookieService,
  ) {
    super(http, router, cookieService);
  };
  
  getaccounts(accountName, accountType, accountState, accountStatus, ExpireBefore, CurrentPage, PageSize) {

    return this.http.post<any>('api/account/getaccounts', {
      "AccountName": accountName,
      "AccountTypeId": accountType,
      "AccountStateId": accountState,
      "IsInActive": accountStatus,
      "ExpireBefore": ExpireBefore,
      "CurrentPage": CurrentPage,
      "PageSize": PageSize
    }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(data => {
        // login successful if there's a jwt token in the response

        if (data) {
          return data;
        }

      }));

    
  }

  getlogintoken(UserId, LoginFrom, LoginTo, PageIndex, PageSize) {

    return this.http.post<any>('api/users/getuserlogintokens', {
      "UserId": UserId,
      "FromDate": LoginFrom,
      "ToDate": LoginTo,
      "PageIndex": PageIndex,
      "PageSize": PageSize
    }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(tokens => {
        if (tokens) {
          return tokens;
        }
      }));
  }

  downloads3file(SessionId, S3Type) {

    return this.http.get<any>('api/admin/downloadfroms3', {
      headers: AppBaseCommonService.httpHeaders, params: {
        "SessionId": SessionId,
        "S3Type": S3Type
      }
    })
      .pipe(map(session => {
        if (session) {

          console.log(session);
          let byteCharacters = atob(session.response);
          let byteNumbers = new Array(byteCharacters.length);

          if (byteCharacters.length == 0) {
            return -1;
          }

          for (var i = 0; i < byteCharacters.length; i++)
            byteNumbers[i] = byteCharacters.charCodeAt(i);

          let byteArray = new Uint8Array(byteNumbers);
          let blob = new Blob([byteArray], { type: session.contentType });

          var blobURL = window.URL.createObjectURL(blob);
          var anchor = document.createElement("a");
          anchor.download = session._ZipFileName;
          anchor.href = blobURL;
          anchor.click();
        }
      }));
  }

  getsessions(UserId, accountId, LoginTokenId, FromDate, ToDate, PageIndex, PageSize) {

    return this.http.post<any>('api/users/getusersessiontokens', {
      "UserId": UserId,
      "AccountId": accountId,
      "LoginTokenId": LoginTokenId,
      "FromDate": FromDate,
      "ToDate": ToDate,
      "PageIndex": PageIndex,
      "PageSize": PageSize
    }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(session => {
        if (session) {
          return session;
        }
      }));
  }

  getreleases(PageIndex, PageSize) {

    return this.http.post<any>('api/admin/getreleases', {
      "PageIndex": PageIndex,
      "PageSize": PageSize
    }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(releases => {
        if (releases) {
          return releases;
        }
      }));
  }

  getdevices(AccountId, PageIndex, PageSize) {

    return this.http.post<any>('api/account/getdevices', {
      "AccountId": AccountId,
      "PageIndex": PageIndex,
      "PageSize": PageSize
    }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(devices => {
        if (devices) {
          return devices;
        }
      }));
  }

  getactivationkeys(AccountId, PageIndex, PageSize) {

    return this.http.post<any>('api/account/getactivationkeys', {
      "AccountId": AccountId,
      "PageIndex": PageIndex,
      "PageSize": PageSize
    }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(keys => {
        if (keys) {
          return keys;
        }
      }));
  }

  savelmmodels(AccountId, AccountLmModels) {

    return this.http.post<any>('api/account/savelmmodels', {
      "AccountId": AccountId,
      "AccountLmModels": AccountLmModels
    }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(lmModel => {
        if (lmModel) {
          return lmModel;
        }
      }));
  }

  getactiveinstallation(AccountId, PageIndex, PageSize) {

    return this.http.post<any>('api/account/getactiveinstallation', {
      "AccountId": AccountId,
      "PageIndex": PageIndex,
      "PageSize": PageSize
    }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(installtion => {
        if (installtion) {
          return installtion;
        }
      }));
  }

  getusers(AccountId, FirstName, UserName, PageIndex, PageSize, IsAdminOnly) {

    return this.http.post<any>('api/users/getusers', {
      "AccountId": AccountId,
      "FirstName": FirstName,
      "UserName": UserName,
      "PageIndex": PageIndex,
      "PageSize": PageSize,
      "IsAdminOnly": IsAdminOnly
    }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(users => {
        // login successful if there's a jwt token in the response

        if (users) {
          return users;
        }

      }));
  }

  getaccountlocation(locationId, countryId) {

    return this.http.post<any>('api/account/getaccountlocation', { "AccountLocationId": locationId, "CountryId": countryId }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(userInfo => {
        if (userInfo) {
          return userInfo;
        }

      }));
  }

  getaccountkey(accountId, keyId) {

    return this.http.post<any>('api/account/getactivationkey', { "AccountId": accountId, "AccountKeyId": keyId }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(userInfo => {
        // login successful if there's a jwt token in the response

        if (userInfo) {
          return userInfo;
        }

      }));
  }

  getuser(accountId, userId) {

    return this.http.post<any>('api/users/getuser', { "AccountId": accountId, "UserId": userId }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(userInfo => {
        // login successful if there's a jwt token in the response

        if (userInfo) {
          return userInfo;
        }

      }));
  }

  getaccount(accountId) {

    return this.http.post<any>('api/account/getaccount', { "AccountId": accountId }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(account => {
        // login successful if there's a jwt token in the response

        if (account) {
          return account;
        }

      }));
  }

  savePhonebookWord(phonebookSaveRequest) {
    return this.http.post<any>('api/phonebook/savephonebookword', phonebookSaveRequest, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(phonebook => {
        if (phonebook) {
          return phonebook;
        }

      }));
  }

  getPhonebookWord(phonebookData) {

    return this.http.post<any>('api/phonebook/getphonebookword', phonebookData, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(phonebook => {
        // login successful if there's a jwt token in the response

        if (phonebook) {
          return phonebook;
        }

      }));
  }

  PhonebookSearchParameters() {

    return this.http.post<any>('api/phonebook/phonebooksearchparameters', '', { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(phonebook => {
        if (phonebook) {
          return phonebook;
        }

      }));

  }

  getWordDetailsBaseLm(request) {

    return this.http.post<any>('api/phonebook/getworddetailsfrombaselm', request, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(phonebook => {
        if (phonebook) {
          return phonebook;
        }

      }));

  }

  getPhonebookWordList(request) {

    return this.http.post<any>('api/phonebook/getphonebookwordslist', request, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(phonebook => {
        if (phonebook) {
          return phonebook;
        }

      }));

  }
  savePhonebookWork(phonebookData) {

    return this.http.post<any>('api/phonebook/addphonebookword', phonebookData, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(phonebook => {
        // login successful if there's a jwt token in the response

        if (phonebook) {
          return phonebook;
        }

      }));
  }

  saveAccountLocation(locationData: AccountLocationModelClass) {

    return this.http.post<any>('api/account/saveaccountlocation', locationData, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(user => {
        if (user) {
          return user;
        }

      }));
  }

  saveuser(UserData: UserDataClass) {

    return this.http.post<any>('api/users/saveuser', UserData, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(user => {
        if (user) {
          return user;
        }

      }));
  }

  saveaccountkey(KeyData: ActivationKeyDataClass) {

    return this.http.post<any>('api/account/saveaccountkey', KeyData, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(user => {
        if (user) {
          return user;
        }

      }));
  }

  saveaccount(AccountData: AccountDataClass) {

    return this.http.post<any>('api/account/saveaccount',
      {
        "AccountId": AccountData.AccountId,
        "AccountName": AccountData.AccountName,
        "AccountCode": AccountData.AccountCode,
        "AccountStateId": AccountData.AccountStateId,
        "AccountTypeId": AccountData.AccountTypeId,
        "SpeechURL": AccountData.SpeechURL,
        "TemplateURL": AccountData.TemplateURL,
        "Status": AccountData.Status,
        "CreatedBy": AccountData.CreatedBy,
        "SalesUserId": AccountData.SalesUserId,
        "PMUserId": AccountData.PMUserId,
        "ProductDemoUserId": AccountData.ProductDemoUserId
      }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(account => {
        if (account) {
          return account;
        }

      }));
  }
  /*
  errorHandler(error: HttpErrorResponse) {
    console.log(error.message);
    return Observable.throw(error.message || "API Error");
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  */
}
