import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { HttpClientModule } from '@angular/common/http';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { AppCommonService } from './services/app-common.service';
import { AppRemoteService } from './services/app-remote.service';

import { GridFilterPipe } from './shared/gridpagingfilter.pipe';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { ConfirmationBoxComponent } from './views/confirmation-box/confirmation-box.component';
import { AccountConfirmationBoxComponent } from './views/confirmation-box/account-confirmation-box.component';
import { PronunciationHelpComponent } from './views/confirmation-box/pronunciation-help.component';
import { MatDialogModule } from '@angular/material';
import { TagInputModule } from 'ngx-chips';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { AlertModule } from 'ngx-bootstrap/alert';
import { UUID } from 'angular2-uuid';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  imports: [
    //BrowserModule,
    TagInputModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxMaterialTimepickerModule,
    AppAsideModule,
    HttpClientModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
	  AlertModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    Ng4LoadingSpinnerModule.forRoot(),
    MatDialogModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: false,
    }) 
  ],
  entryComponents: [
    ConfirmationBoxComponent,
    AccountConfirmationBoxComponent,
    PronunciationHelpComponent
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    GridFilterPipe,
    ConfirmationBoxComponent,
    AccountConfirmationBoxComponent,
    PronunciationHelpComponent
  ],
  providers: [{
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  }, AppCommonService, AppRemoteService, CookieService],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
