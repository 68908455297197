import { Component, ViewEncapsulation, ElementRef  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertConfig } from 'ngx-bootstrap/alert';
import { DomSanitizer } from '@angular/platform-browser';
import { AppCommonService } from '../../services/app-common.service'
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { CookieService } from 'ngx-cookie-service';

export function getAlertConfig(): AlertConfig {
  return Object.assign(new AlertConfig(), { type: 'success' });
}

@Component({
  selector: 'app-dashboard',
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'login.component.html',
  providers: [{ provide: AlertConfig, useFactory: getAlertConfig }]
})
export class LoginComponent {
  public userName = "";
  public password = "";
  public ShowLoginErrorMessage = "none";
  constructor(
    private router: Router,
    private appCommonService: AppCommonService,
    private spinnerService: Ng4LoadingSpinnerService,
    private cookieService: CookieService,
    private elementReferene: ElementRef,
  ) {

    var loginToken = this.cookieService.get('loginToken');
    if (loginToken != null && loginToken != '') {
      this.router.navigate(['/accounts']);
    }
  }
  ngOnInit(): void {
    const userNameControl = this.elementReferene.nativeElement.querySelector('.userName');
    if (userNameControl) {
      userNameControl.focus();
    }
  }
  onKeydown(event) {
    if (event.key === "Enter") {
      this.onSubmit();
    }
  }
  onSubmit(): void {

    this.spinnerService.show();
    this.appCommonService.login(this.userName, this.password)
      .subscribe(
        data => {
          //console.log(data);
          if (data != null && data.UserID != null) {
            this.ShowLoginErrorMessage = "none";
            this.spinnerService.hide();
            this.router.navigate(['/home']);
          }
          else {
            this.spinnerService.hide();
            this.ShowLoginErrorMessage = "";
          }
        },
        error => {
          this.spinnerService.hide();
          console.log("Error", error);
        }
      )

    /*
    if (this.userName == "admin" && this.password == "admin") {
      this.ShowLoginErrorMessage = "none";
      this.router.navigate(['/home']);
    }
    else {
      this.ShowLoginErrorMessage = "";
    }*/

  }
}
