import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'GridFilter',
  pure: false
})
export class GridFilterPipe implements PipeTransform {
  transform(items: any[], filter: Object): any {
    if (!items || !filter) {
      return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    //filter = +filter; //parse to int
    return items.slice(Number(filter));
  }
}
