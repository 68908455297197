interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
}

export const navItems: NavData[] = [
  //{
  //  name: 'Dashboard',
  //  url: '/dashboard',
  //  icon: 'icon-speedometer',
  //},
  {
    name: 'Account Setting',
    url: '/accounts/accountlist',
    icon: 'icon-people'
  },
  {
    name: 'User Usage Report',
    url: '/users/userlist',
    icon: 'icon-eye'
  }, 
  {
    name: 'Devices',
    url: '/widgets/installer',
    icon: 'cui-laptop',
  },
  {
    name: 'Widget Release',
    url: '/widgets/session',
    icon: 'cui-shield',
  },
  {
    name: 'Support Ticket',
    url: '/ticket/list',
    icon: 'fa fa-bug'
  },
  {
    name: 'Push Notification',
    url: '/pushnotification/list',
    icon: 'fa fa-bell'
  },
  {
    name: 'Add new Word',
    url: '/phonebook/phonebookadd',
    icon: 'fa fa-id-card fa-lg',
  },
  {
    name: 'QA Word',
    url: '/phonebook/phonebookwordqa',
    icon: 'fa fa-book',
  },
  {
    name: 'All Phonobook Words',
    url: '/phonebook/wordslist',
    icon: 'fa fa-search',
  },
  {
    name: 'All Base LM Words',
    url: '/phonebook/wordinfo',
    icon: 'fa fa-search',
  },
  {
    divider: true
  }
];
