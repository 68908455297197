import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: 'app-confirmation-box',
  template: `<h3 mat-dialog-title>Pronunciation Help</h3>
              <mat-dialog-content class="mat-typography">
                  <div style="max-height:400px;height:400px">
                   <table border="1" padding="5">
                      <tr>
                          <td><b>Phoneme</b></td>
                          <td><b>Example</b></td>
                          <td><b>Translation</b></td>
                      <tr>
                      <tr>
                          <td>AE</td>
                          <td>at</td>
                          <td>AE T</td>
                      <tr>
                      <tr>
                          <td>AH</td>
                          <td>hut</td>
                          <td>HH AH T</td>
                      <tr>
                      <tr>
                          <td>AO</td>
                          <td>ought</td>
                          <td>AO T</td>
                      <tr>
                      <tr>
                          <td>AW</td>
                          <td>cow</td>
                          <td>K AW</td>
                      <tr>
                      <tr>
                          <td>AY</td>
                          <td>hide</td>
                          <td>HH AY D</td>
                      <tr>
                      <tr>
                          <td>B</td>
                          <td>be</td>
                          <td>B IY</td>
                      <tr>
                      <tr>
                          <td>CH</td>
                          <td>cheese</td>
                          <td>CH IY Z</td>
                      <tr>
                      <tr>
                          <td>D</td>
                          <td>dee</td>
                          <td>D IY</td>
                      <tr>
                      <tr>
                          <td>DH</td>
                          <td>thee</td>
                          <td>DH IY</td>
                      <tr>
                      <tr>
                          <td>EH</td>
                          <td>Ed</td>
                          <td>EH D</td>
                      <tr>
                      <tr>
                          <td>ER</td>
                          <td>hurt</td>
                          <td>HH ER T</td>
                      <tr>
                      <tr>
                          <td>EY</td>
                          <td>ate</td>
                          <td>EY T</td>
                      <tr>
                      <tr>
                          <td>F</td>
                          <td>fee</td>
                          <td>F IY</td>
                      <tr>
                      <tr>
                          <td>G</td>
                          <td>green</td>
                          <td>G R IY N</td>
                      <tr>
                      <tr>
                          <td>HH</td>
                          <td>he</td>
                          <td>HH IY</td>
                      <tr>
                      <tr>
                          <td>IH</td>
                          <td>it</td>
                          <td>IH T</td>
                      <tr>

                      <tr>
                          <td>IY</td>
                          <td>eat</td>
                          <td>IY T</td>
                      <tr>
                      <tr>
                          <td>JH</td>
                          <td>gee</td>
                          <td>JH IY</td>
                      <tr>
                      <tr>
                          <td>K</td>
                          <td>key</td>
                          <td>K IY</td>
                      <tr>
                      <tr>
                          <td>L</td>
                          <td>lee</td>
                          <td>L IY</td>
                      <tr>
                      <tr>
                          <td>M</td>
                          <td>me</td>
                          <td>M IY</td>
                      <tr>
                      <tr>
                          <td>N</td>
                          <td>knee</td>
                          <td>N IY</td>
                      <tr>
                      <tr>
                          <td>NG</td>
                          <td>ping</td>
                          <td>P IH NG</td>
                      <tr>
                      <tr>
                          <td>OW</td>
                          <td>oat</td>
                          <td>OW T</td>
                      <tr>
                      <tr>
                          <td>OY</td>
                          <td>toy</td>
                          <td>T OY</td>
                      <tr>
                      <tr>
                          <td>P</td>
                          <td>pee</td>
                          <td>P IY</td>
                      <tr>
                      <tr>
                          <td>R</td>
                          <td>read</td>
                          <td>R IY D</td>
                      <tr>
                      <tr>
                          <td>S</td>
                          <td>sea</td>
                          <td>S IY</td>
                      <tr>
                      <tr>
                          <td>SH</td>
                          <td>she</td>
                          <td>SH IY</td>
                      <tr>
                      <tr>
                          <td>T</td>
                          <td>tea</td>
                          <td>T IY</td>
                      <tr>
                      <tr>
                          <td>TH</td>
                          <td>theta</td>
                          <td>TH EY T AH</td>
                      <tr>
                      <tr>
                          <td>UH</td>
                          <td>hood</td>
                          <td>HH UH D</td>
                      <tr>
                      <tr>
                          <td>UW</td>
                          <td>two</td>
                          <td>T UW</td>
                      <tr>
                      <tr>
                          <td>V</td>
                          <td>vee</td>
                          <td>V IY</td>
                      <tr>
                      <tr>
                          <td>W</td>
                          <td>we</td>
                          <td>W IY</td>
                      <tr>
                      <tr>
                          <td>Y</td>
                          <td>yield</td>
                          <td>Y IY L D</td>
                      <tr>
                      <tr>
                          <td>Z</td>
                          <td>zee</td>
                          <td>Z IY</td>
                      <tr>
                      <tr>
                          <td>ZH</td>
                          <td>seizure</td>
                          <td>S IY ZH ER</td>
                      <tr>
                      
                   </table>
                  </div>
                </mat-dialog-content>
                <mat-dialog-actions align="end">
                  <button mat-button (click)="close()">Close</button>
                  
              </mat-dialog-actions>`,
  styles: []
})
export class PronunciationHelpComponent implements OnInit {

  dialog: MatDialogRef<PronunciationHelpComponent>;
  constructor(private dialogRef: MatDialogRef<PronunciationHelpComponent>) {
    this.dialog = this.dialogRef;
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close(false);
  }

}
