import { Injectable, EventEmitter, Input, Output, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, observable } from 'rxjs';
import { AccountDataClass } from '../classes/AccountDataClass';
import { UserDataClass, AccountLocationModelClass } from '../classes/UserDataClass';
import { ActivationKeyDataClass } from '../classes/AccountDataClass';
import { map, take, first } from 'rxjs/operators';

import { catchError } from "rxjs/internal/operators";
import { debug } from 'util';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})

export class AppBaseCommonService  {
  protected static httpHeaders: HttpHeaders;
  protected static httpFileUploadHeaders: HttpHeaders;
  protected static headers: HttpHeaders;
  public static serviceURL = "";
  @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();
  @Output() getAuthTokenUpdate: EventEmitter<any> = new EventEmitter();


  constructor(protected http: HttpClient,
    protected router: Router,
    protected cookieService: CookieService,
  ) {
    this.APIHeader();
    this.registerCloseEvent();
  };

  registerCloseEvent() {
    let cookieServiceLocal = this.cookieService;
    var setCookieExpire = function (seconds, minutes) {

      var dt = new Date(Date.now());
      if (minutes > 0) {
        dt.setMinutes(dt.getMinutes() + minutes);
      }
      if (seconds > 0) {
        dt.setSeconds(dt.getSeconds() + 10);
      }

      var cookiesData = cookieServiceLocal.get("loginToken");
      if (cookiesData != null && cookiesData != '') {
        cookieServiceLocal.set('loginToken', cookiesData, dt, null, null, true, "Strict");

        cookiesData = cookieServiceLocal.get('UserID');
        cookieServiceLocal.set('UserID', cookiesData, dt, null, null, true, "Strict");

        cookiesData = cookieServiceLocal.get('UserCode');
        cookieServiceLocal.set('UserCode', cookiesData, dt, null, null, true, "Strict");

        cookiesData = cookieServiceLocal.get('UserName');
        cookieServiceLocal.set('UserName', cookiesData, dt, null, null, true, "Strict");
      }

    };
    window.addEventListener("keydown", function (e) {
      setCookieExpire(0, 120);
    });
    window.addEventListener("mousedown", function (e) {
      setCookieExpire(0, 120);
    });

    window.addEventListener("load", function (e) {
      setCookieExpire(0, 120);
    });
    window.addEventListener("beforeunload", function (e) {
      if (!isDevMode())
      {
        setCookieExpire(10, 0);
      }
    });
  }

  RequiredRedirectToLogin(error) {
    if (error && (error.status == 500)) {
      this.cookieService.delete("UserID");
      this.cookieService.delete('UserCode');
      this.cookieService.delete('loginToken');
      this.cookieService.delete('UserName');

      this.router.navigate(['/login']);
    }

  }


  APIHeader() {
    var currentUser = this.cookieService.get('UserCode');
    var loginToken = this.cookieService.get('loginToken');
    if (AppBaseCommonService.serviceURL == "") {
      AppBaseCommonService.serviceURL = this.cookieService.get('ServiceURL');
    }

    AppBaseCommonService.httpHeaders = new HttpHeaders
      (
      {
        'Content-Type': 'application/json',
        'CurrentUser': currentUser,
        'LoginToken': loginToken
      }
    )
    AppBaseCommonService.httpFileUploadHeaders = new HttpHeaders
      (
      {
        'CurrentUser': currentUser,
        'LoginToken': loginToken
      }
      )
    
    this.getserviceURL().subscribe(data => {
      AppBaseCommonService.serviceURL = data.ServiceURL;
    });
  }

  login(username: string, password: string) {


    return this.http.post<any>('api/login/authenticate', { 'UserName': username, 'Password': password })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.UserID != null) {
          var dt = new Date(Date.now());
          dt.setMinutes(dt.getMinutes() + 120);
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.cookieService.set('UserID', user.UserID, dt, null, null, true, "Strict");
          this.cookieService.set('UserCode', user.UserCode, dt, null, null, true, "Strict");
          this.cookieService.set('loginToken', user.Token, dt, null, null, true, "Strict");
          this.cookieService.set("UserName", user.UserName, dt, null, null, true, "Strict");

          this.APIHeader();
        }

        return user;
      }));
  }


  getserviceURL() {

    return this.http.post<any>('api/account/getserviceURL', { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(data => {
        // login successful if there's a jwt token in the response

        if (data) {
          AppBaseCommonService.serviceURL = data.ServiceURL;
          var dt = new Date(Date.now());
          dt.setMinutes(dt.getMinutes() + 120);
          this.cookieService.set("ServiceURL", data.ServiceURL, dt, null, null, true, "Strict");
          return data;
        }

      }));
  }
  
}
