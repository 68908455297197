import { Injectable, EventEmitter, Input, Output } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, observable,empty } from 'rxjs';
import { AccountDataClass, SubscriptionDataClass, OfflinePaymentDataClass } from '../classes/AccountDataClass';
import { UserDataClass, AccountLocationModelClass } from '../classes/UserDataClass';
import { ActivationKeyDataClass } from '../classes/AccountDataClass';
import { map, take, first } from 'rxjs/operators';

import { catchError } from "rxjs/internal/operators";
import { debug } from 'util';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { AppBaseCommonService } from './app-base-common.service';
import { NumericBlurEventArgs } from '@syncfusion/ej2-inputs';
import { PushNotificationManageClass } from '../classes/PushNotificationDataClass';

@Injectable({
  providedIn: 'root'
})

export class AppRemoteService extends AppBaseCommonService {
  
  constructor(protected http: HttpClient,
    protected router: Router,
    protected cookieService: CookieService,
  ) {
    super(http, router, cookieService);
  };

  
  getaccountsServiceCall() {

    return this.http.post<any>(AppBaseCommonService.serviceURL + 'WebAdminPortalService.svc/AdminPortal/Accounts', {
      "AccountsRequest": {
        "AccountTypeId": 0,
        "AccountStateId": 0,
        "IsInActive": null,
        "ExpireBefore": null
      }
    }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(account => {
        // login successful if there's a jwt token in the response

        if (account) {
          return account;
        }

      }));
  }

  getallofflinepayment(accountId: number, activeSubscriptionId: number) {

    return this.http.post<any>(AppBaseCommonService.serviceURL + 'WebAdminPortalService.svc/AdminPortal/GetAllOffLinePayment', {
      "request": {
        "AccountId": accountId,
        "ActiveSubscriptionId": activeSubscriptionId
      }
    }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(account => {
        // login successful if there's a jwt token in the response

        if (account) {
          return account;
        }

      }));
  }
  saveAccountConfig(accountCode, parameterName, parameterValue) {
    return this.http.post<any>(AppBaseCommonService.serviceURL + 'WebAdminPortalService.svc/AdminPortal/SaveConfigurationParam', {
      "request": {
        "LoginUserId": this.cookieService.get('UserID'),
        "AccountCode": accountCode,
        "ConfigParams": [{ "Name": parameterName, "Value": parameterValue}]
      }
    }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(accountSetting => {
        if (accountSetting) {
          return accountSetting;
        }

      }));
  }

  getResetSpeechAPIKey(accountId: number) {

    return this.http.post<any>(AppBaseCommonService.serviceURL + 'WebAdminPortalService.svc/AdminPortal/resetapiaccesskey', {
      "request": {
        "AcccountId": accountId,
        "LoginUser": this.cookieService.get('UserID')
      }
    }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(apiKey => {
        if (apiKey) {
          return apiKey;
        }

      }));
  }

  getAccountConfig(accountId: number) {

    return this.http.post<any>(AppBaseCommonService.serviceURL + 'WebAdminPortalService.svc/AdminPortal/AccountConfigSetting', {
      "request": {
        "AccountId": accountId
      }
    }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(accountSetting => {
        if (accountSetting) {
          return accountSetting;
        }

      }));
  }
  GetSubscription(accountId: number) {

    return this.http.post<any>(AppBaseCommonService.serviceURL + 'WebAdminPortalService.svc/AdminPortal/GetSubscription', {
      "request": {
        "AccountId": accountId
      }
    }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(account => {
        // login successful if there's a jwt token in the response

        if (account) {
          return account;
        }

      }));
  }
  saveSubscription(UserSubscriptionData: SubscriptionDataClass) {
    return this.http.post<any>(AppBaseCommonService.serviceURL + 'WebAdminPortalService.svc/AdminPortal/saveofflinepayment', {
      "request": UserSubscriptionData
    }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(account => {
        // login successful if there's a jwt token in the response

        if (account) {
          return account;
        }

      }));
  }
  ExtendSubscription(UserOfflinePaymentData: OfflinePaymentDataClass) {
    return this.http.post<any>(AppBaseCommonService.serviceURL + 'WebAdminPortalService.svc/AdminPortal/ExtendSubscription', {
      "request": UserOfflinePaymentData
    }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(account => {
        // login successful if there's a jwt token in the response

        if (account) {
          return account;
        }

      }));
  }
  getOfflinePaymentDetails(accountId: number, activeSubscriptionId: number) {

    return this.http.post<any>(AppBaseCommonService.serviceURL + 'WebAdminPortalService.svc/AdminPortal/GetOfflineSubscription', {
      "request": {
        "AccountId": accountId,
        "ActiveSubscriptionId": activeSubscriptionId
      }
    }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(account => {
        // login successful if there's a jwt token in the response

        if (account) {
          return account;
        }

      }));
  }
  getOfflinePaymentDetailsInfo(accountId: number, activeSubscriptionId: number) {

    return this.http.post<any>(AppBaseCommonService.serviceURL + 'WebAdminPortalService.svc/AdminPortal/GetAllOffLinePayment', {
      "request": {
        "AccountId": accountId,
        "ActiveSubscriptionId": activeSubscriptionId
      }
    }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(account => {
        // login successful if there's a jwt token in the response

        if (account) {
          return account;
        }

      }));
  }
  
  saveTicket(requestData) {
    return this.http.post<any>(AppBaseCommonService.serviceURL + 'AugnitoSupportService.svc/AugnitoSupport/SaveSupportTicket', requestData , { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(response => {
        // login successful if there's a jwt token in the response

        if (response) {
          return response;
        }

      }));
  }

  getAccountList() {
    return this.http.post<any>(AppBaseCommonService.serviceURL + 'WebAdminPortalService.svc/AdminPortal/GetDropDownAccounts', {
      
    }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(response => {
        // login successful if there's a jwt token in the response

        if (response) {
          return response;
        }

      }));
  }

  savePushNotification(notificationData: PushNotificationManageClass) {
    return this.http.post<any>(AppBaseCommonService.serviceURL + 'WebAdminPortalService.svc/AdminPortal/ManagePushNotification', {
      "request": notificationData
    }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(response => {
        if (response) {
          return response;
        }
      }));
  }
  stopPushNotification(notificationId) {
    return this.http.post<any>(AppBaseCommonService.serviceURL + 'WebAdminPortalService.svc/AdminPortal/ManagePushNotification', {
      "request": {
        "NotificationId": notificationId,
        "Status": "Stopped"
      }
    }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(response => {
        if (response) {
          return response;
        }
      }));
  }
  uploadCSVFile(fileToUpload: File){
    const endpoint = AppBaseCommonService.serviceURL + 'WebAdminPortalService.svc/AdminPortal/UploadCSVFile';
    const formData: FormData = new FormData();
    formData.append('stream', fileToUpload);
    return this.http
      .post<any>(endpoint, formData, { headers: AppBaseCommonService.httpFileUploadHeaders })
      .pipe(map(response => {
        if (response) {
          return response;
        }
      }));
  }

  getNotificationData() {
    return this.http.post<any>(AppBaseCommonService.serviceURL + 'WebAdminPortalService.svc/AdminPortal/GetNotificationData', {
    }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(response => {
        if (response) {
          return response;
        }
      }));
  }

  getPushNotificationExecutionDetails(notificationId: number, notificationExecutionId: number, PageNumber: number, PageSize: number) {
    return this.http.post<any>(AppBaseCommonService.serviceURL + 'WebAdminPortalService.svc/AdminPortal/GetPushNotificationExecutionDetails', {
      "request": {
        "NotificationId": notificationId,
        "NotificationExecutionId": notificationExecutionId,
        "PageNumber": PageNumber,
        "PageSize": PageSize
      }
    }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(response => {
        if (response) {
          return response;
        }
      }));
  }

  getPushNOtificationExecutionList(notificationId: number, PageNumber: number, PageSize: number) {
    return this.http.post<any>(AppBaseCommonService.serviceURL + 'WebAdminPortalService.svc/AdminPortal/GetPushNotificationExecution', {
      "request": {
        "NotificationId": notificationId,
        "PageNumber": PageNumber,
        "PageSize": PageSize
      }
    }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(response => {
        if (response) {
          return response;
        }
      }));
  }

  getPushNOtificationList(Status: string, Tags: string[], PageNumber: number, PageSize: number) {
    return this.http.post<any>(AppBaseCommonService.serviceURL + 'WebAdminPortalService.svc/AdminPortal/GetPushNotification', {
      "request": {
        "Status": Status,
        "Tags": Tags,
        "PageNumber": PageNumber,
        "PageSize": PageSize
      }
    }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(response => {
        if (response) {
          return response;
        }
      }));
  }
 
  getUserList(accountId) {
    return this.http.post<any>(AppBaseCommonService.serviceURL + 'WebAdminPortalService.svc/AdminPortal/GetDropDonwUsers', {
      "RequestData": {
        "AccountId": accountId
      }
    }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(response => {
        // login successful if there's a jwt token in the response

        if (response) {
          return response;
        }

      }));
  }


  getDropDownAccount() {
    return this.http.post<any>(AppBaseCommonService.serviceURL + 'WebAdminPortalService.svc/AdminPortal/GetDropDownAccounts', {},
      { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(response => {
        // login successful if there's a jwt token in the response

        if (response) {
          return response;
        }

      }));
  }

  getSupportUserList() {
    return this.http.post<any>(AppBaseCommonService.serviceURL + 'WebAdminPortalService.svc/AdminPortal/GetDropDonwUsers', {
        "RequestData": {
          "OnlyInternal": 1
        }
    }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(response => {
        // login successful if there's a jwt token in the response

        if (response) {
          return response;
        }

      }));
  }

  getTicketList(requestData) {
    
    return this.http.post<any>(AppBaseCommonService.serviceURL + 'AugnitoSupportService.svc/AugnitoSupport/GetListOfTickets', requestData, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(response => {
        // login successful if there's a jwt token in the response

        if (response) {
          return response;
        }

      }));
  }

  getTicketDetail(ticketId: number, pageSize: number, pageNumber: number) {
    let requestData = {
      "RequestData":
      {
        "TicketId": Number(ticketId),
        "PageSize": pageSize,
        "PageNum": pageNumber
      }
    }
    return this.http.post<any>(AppBaseCommonService.serviceURL + 'AugnitoSupportService.svc/AugnitoSupport/GetTicketDetails', requestData, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(response => {
        // login successful if there's a jwt token in the response

        if (response) {
          return response;
        }

      }));
  }

  editTicket(requestData) {
   
   return this.http.post<any>(AppBaseCommonService.serviceURL + 'AugnitoSupportService.svc/AugnitoSupport/UpdateSupportTicket', requestData, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(response => {
        // login successful if there's a jwt token in the response

        if (response) {
          return response;
        }

      }));
 }


  addComment(formData, pageSize: number, pageNumber: number) {
    let requestData = {
      "RequestData": {
        ...formData,
        "TicketId": Number(formData.TicketId),
        "CreatedUserId": Number(formData.CreatedUserId),
        "PageSize": pageSize,
        "PageNum": pageNumber
      }
    }
    return this.http.post<any>(AppBaseCommonService.serviceURL + 'AugnitoSupportService.svc/AugnitoSupport/AddTicketComment', requestData, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(response => {
        // login successful if there's a jwt token in the response

        if (response) {
          return response;
        }

      }));
  }

  getTicketTypeState() {
    return this.http.post<any>(AppBaseCommonService.serviceURL + 'AugnitoSupportService.svc/AugnitoSupport/GetTicketTypeAndState', { }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(response => {
        // login successful if there's a jwt token in the response

        if (response) {
          return response;
        }

      }));
  }
  GetTicketAttributes() {
    return this.http.post<any>(AppBaseCommonService.serviceURL + 'AugnitoSupportService.svc/AugnitoSupport/GetTicketAttributes', {}, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(response => {
        // login successful if there's a jwt token in the response

        if (response) {
          return response;
        }

      }));
  }

  DeleteTicketAttachment(ticketData) {
    return this.http.post<any>(AppBaseCommonService.serviceURL + 'AugnitoSupportService.svc/AugnitoSupport/DeleteTicketAttachment', ticketData, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(response => {
        if (response) {
          return response;
        }

      }));
  }

  SearchUser(serachParameters) {
    return this.http.post<any>(AppBaseCommonService.serviceURL + 'WebAdminPortalService.svc/AdminPortal/SearchUser', serachParameters, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(response => {
        // login successful if there's a jwt token in the response

        if (response) {
          return response;
        }

      }));
  }

  GetDropDownAccounts(serachParameters) {
    return this.http.post<any>(AppBaseCommonService.serviceURL + 'WebAdminPortalService.svc/AdminPortal/GetDropDownAccounts', serachParameters, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(response => {
        // login successful if there's a jwt token in the response

        if (response) {
          return response;
        }

      }));
  }

  GetDropDownAccountUser(serachParameters) {
    return this.http.post<any>(AppBaseCommonService.serviceURL + 'WebAdminPortalService.svc/AdminPortal/GetDropDownAccountUsers', serachParameters, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(response => {
        // login successful if there's a jwt token in the response

        if (response) {
          return response;
        }

      }));
  }

  GetTicketReportView() {
    return this.http.post<any>(AppBaseCommonService.serviceURL + 'AugnitoSupportService.svc/AugnitoSupport/GetTicketReportView', {}, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(response => {
        // login successful if there's a jwt token in the response

        if (response) {
          return response;
        }

      }));
  }

  //GetSpeechModelList(userId) {
  //  return this.http.post<any>(AppBaseCommonService.serviceURL + 'WebAdminPortalService.svc/AdminPortal/GetSpeechModelList', { "userId": userId}, { headers: AppBaseCommonService.httpHeaders })
  //    .pipe(map(response => {
  //      if (response) {
  //        return response;
  //      }
  //    }));
  //}

  GetAllSpeechModelList() {
    return this.http.post<any>(AppBaseCommonService.serviceURL + 'WebAdminPortalService.svc/AdminPortal/GetAllSpeechModelList', { }, { headers: AppBaseCommonService.httpHeaders })
      .pipe(map(response => {
        if (response) {
          return response;
        }
      }));
  }
}
