import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: 'app-confirmation-box',
  template: `<h3 mat-dialog-title>Remark</h3>
              <mat-dialog-content class="mat-typography">
                   <textarea matInput placeholder="Remark"  #input rows="3"></textarea>
                </mat-dialog-content>
                <mat-dialog-actions align="end">
                  <button mat-button (click)="close()">Cancel</button>
                  <button mat-button (click)="dialog.close(input.value)" cdkFocusInitial>Submit</button>
              </mat-dialog-actions>`,
  styles: []
})
export class ConfirmationBoxComponent implements OnInit {

  dialog: MatDialogRef<ConfirmationBoxComponent>;
  constructor(private dialogRef: MatDialogRef<ConfirmationBoxComponent>) {
    this.dialog = this.dialogRef;
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close(false);
  }

}
